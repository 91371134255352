import React from 'react';

import Navbar from './navbar';

const LayoutSimple = ({ children }) => {

  return (
    <div id="wrapper">
      <Navbar />
      <div className="page-wrapper">
        <section className="pb-5 h-100vh">
          <div className="home-center">
            <div className="home-desc-center">
              <div className="container">
                {children}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LayoutSimple;
