import React from 'react';
import { graphql } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import LayoutSimple from '../components/layout-simple';
import Seo from '../components/seo';

const About = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  return (
    <LayoutSimple location={location} title={siteTitle}>
      <Seo title="About Us" />
      <div className="row justify-content-center">
        <div className="col-12">

          <article className="post">

            <h2 className="text-center mt-0 mb-5 pb-3 simple-page-title"><span>About K2V Academy</span></h2>

            <div className="blog-detail-description">
              <p>We are technology enthusiasts eager to share the knowledge we have with the community. We are dedicated to bringing you the best educational content related to Cloud Technologies, Software Development, DevOps, Security, and more. We focus on the Microsoft technology stack and the surrounding technology landscape. Our authors have years of experience and expertise on topics related to Microsoft Azure, DevOps, .Net Technologies.</p>

              <p>Started in 2014 by our founder <OutboundLink href="https://kasunkodagoda.com" target="_blank" rel="noreferrer" aria-label="Kasun Kodagoda" >Kasun Kodagoda</OutboundLink>, we have been under several brands before. We have rebranded ourselves as K2V Academy with a vision to grow as a trusted source of educational content under a unified brand.</p>

              <blockquote className="blockquote text-center">
                <h6 className="mb-0 blockquote-text">If you are interested in sharing your knowledge with the community under the K2V Academy, do not hesitate to contact us. We are excited to bring in new talent and technology experts under K2V Academy to share the knowledge with the community.</h6>
                <footer className="blockquote-footer mt-2">Kasun Kodagoda, Founder K2V Academy</footer>
              </blockquote>

              <p>We will be bringing you technical content in video and written article formats. Subscribe to our <OutboundLink href="https://www.youtube.com/c/K2VAcademy" target="_blank" rel="noreferrer" aria-label="Kasun Kodagoda" >YouTube channel</OutboundLink> if you are interested in tech videos to learn the latest technology. If you are someone, who likes to read, the K2V Academy Blog is the right place for you. Follow our social media channels to keep yourself updated. We hope you are excited about this journey as much as we are.</p>

              <ul className="socials list-unstyled mb-0 text-center mt-5">
                <li key="facebook"><OutboundLink href="https://www.facebook.com/K2VAcademy" target="_blank" rel="noreferrer" aria-label="Facebook"><i className="icon-facebook"></i></OutboundLink></li>
                <li key="twitter"><OutboundLink href="https://twitter.com/K2vAcademy" target="_blank" rel="noreferrer" aria-label="Twitter"><i className="icon-twitter"></i></OutboundLink></li>
                <li key="linkedin"><OutboundLink href="https://www.linkedin.com/company/k2vacademy/" target="_blank" rel="noreferrer" aria-label="LinkedIn"><i className="icon-linkedin2"></i></OutboundLink></li>
                <li key="youtube"><OutboundLink href="https://www.youtube.com/c/K2VAcademy" target="_blank" rel="noreferrer" aria-label="YouTube"><i className="icon-youtube"></i></OutboundLink></li>
              </ul>

            </div>

          </article>
        </div>
      </div>
    </LayoutSimple>
  )
}

export default About;

export const pageQuery = graphql`
      query {
        site {
        siteMetadata {
        title
      }
    }
  }
`
